import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { fetchAllProducts } from "@app/services/services/fetchProductById";
import userService from "@app/services/users/userService";
import Breadcrumb from "@components/Breadcrumb";
import DataTable from "@components/DataTableV2/DataTable";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import UserContainer from "@layout/UserContainer";

import { CustomerPermissions } from "@src/enum/Permissions";

import { ROUTES, ORDERS_API } from "@utils/constant";

const searchableColumns = [
  "id",
  "number",
  "customer.customer_name",
  "user.firstname",
  "user.lastname",
];

const List = () => {
  const { hasAllPermissions } = useAuthorization();
  const history = useHistory();
  const { messages } = useIntl();
  const { customer = {} } = useSelector(state => state.authReducer.userInfo);
  const { showErrorToast } = useToast();

  const [services, setServices] = useState([]);
  const [userData, setUserData] = useState([]);
  const [isFilterValuesLoaded, setIsFilterValuesLoaded] = useState(false);

  const hasOrdersWriteSelfReadPermission = hasAllPermissions([
    CustomerPermissions.AdminWriteOrders,
    CustomerPermissions.CustomerReadSelfOrders,
  ]);

  const hasPricesReadPermission = hasAllPermissions([
    CustomerPermissions.CustomerReadPrices,
  ]);

  const handleCreateOrder = () => {
    history.push(ROUTES.SEARCH.URL);
  };

  useEffect(() => {
    const loadServices = async () => {
      try {
        const [servicesResponse, users] = await Promise.all([
          fetchAllProducts({
            customer_id: customer?.id,
          }),

          userService("filter[type]=customer&per_page=100"),
        ]);

        const {
          data: { data = [] },
        } = servicesResponse;
        const servicesData = data?.map(service => {
          const { name = "", id = "" } = service;
          return { name: name, code: id };
        });
        const { data: { data: userData = [] } = {} } = users;
        await setUserData(
          userData.map(o => ({ name: `${o?.name} #${o?.id}`, code: o?.id }))
        );
        await setServices(servicesData);
        setIsFilterValuesLoaded(true);
      } catch (e) {
        showErrorToast(messages.exception_error_message);
      }
    };

    loadServices();
  }, [customer?.id, messages.exception_error_message, showErrorToast]);

  const fetchUserName = orderItems => {
    const personNames = orderItems.map(o => {
      return o.person_initials;
    });
    const uniqueUsers = [...new Set(personNames)];
    const lengthOfUsers = uniqueUsers.length;
    if (!lengthOfUsers) return "-";
    if (uniqueUsers[0] !== "") {
      if (lengthOfUsers === 1) return uniqueUsers[0];
      else return `${uniqueUsers[0]} + ${lengthOfUsers - 1}`;
    } else return "-";
  };

  const config = {
    header: {
      actions: [
        {
          id: "new-order",
          type: "button",
          label: messages.label_new_order,
          onClick: handleCreateOrder,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasOrdersWriteSelfReadPermission,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: messages.filter_description,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "orders.csv",
    enable_filter: true,
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        label: messages.label_date,
        filter_by_startdate: "filter[created_at][eq]",
        options: [
          {
            label: messages.filter_date_from,
            value: "",
            id: ["filter[created_at][gte]", "filter[created_at][lte]"],
          },
        ],
      },
      {
        title: messages.title_users,
        id: "filter[user_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[user_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[user_id]",
            options: userData,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.label_services,
        id: "filter[orderItems.product_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[orderItems.product_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[orderItems.product_id]",
            options: services,
            type: "multiselect",
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_customer_orders_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${ORDERS_API}`,
      method: "GET",
      search_fields: searchableColumns,
      params: [
        [
          "fields",
          [
            "id",
            "created_at",
            "price_after_adjustments",
            "customer_id",
            "user_id",
          ],
        ],
        ["include", ["customer", "user", "orderItems", "payments"]],
        ["filter[type]", "PO"],
      ],
    },
    is_filter_options_updated: isFilterValuesLoaded,
    columns: [
      {
        title: "watchlist_title_order_id",
        db_field: "id",
        type: "id",
        className: "text-blue",
        width: "100px",
        href: values => {
          const { id } = values;
          return `${ROUTES.ORDERS_DETAILS.URL}?id=${id}`;
        },
        sortable: true,
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
        width: "15%",
      },
      {
        title: "label_purchaser",
        db_field: "user.name",
        type: "text",
        sortable: true,
        sortField: "user.firstname",
        formatter: value => value || messages.label_deleted_user,
      },
      {
        title: "datasource_candidate",
        db_field: "order_items",
        type: "text",
        formatter: value => {
          return fetchUserName(value);
        },
        width: "100px",
        sortable: true,
        sortField: "orderItems.person_initials",
      },
      {
        title: "label_service",
        db_field: "order_items",
        type: "text",
        width: "33%",
        sortable: true,
        sortField: "orderItems.name",
        formatter: value => {
          const totalOrderItems = value?.length;
          return totalOrderItems > 1
            ? `${value[0]?.name} + ${totalOrderItems - 1}`
            : value[0]?.name;
        },
      },
    ],
  };

  const priceColumn = {
    title: "watchlist_label_price",
    db_field: "price_after_adjustments",
    type: "currency",
    sortable: true,
    width: "10%",
  };

  const rowActions = {
    type: "actions",
    width: "50px",
    actions: [
      {
        type: "link",
        href: ({ id }) => `${ROUTES.ORDERS_DETAILS.URL}?id=${id}`,
      },
    ],
  };

  const headerConfig = {
    title: messages.label_order_page_header,
  };

  if (hasPricesReadPermission) {
    config.columns.push(priceColumn);
  }

  config.columns.push(rowActions);

  const breadCrumbItems = [
    {
      label: messages.my_profile,
    },
    { label: messages.label_order_page },
  ];

  return (
    <UserContainer config={headerConfig} wide>
      <Breadcrumb p={0} items={breadCrumbItems} />
      <DataTable config={config} />
    </UserContainer>
  );
};

export default List;
