import React from "react";

import PropTypes from "prop-types";
import Div from "@components/Div";
import styled from "styled-components";

const StyledDiv = styled(Div)`
  gap: 16px;
`;

const Chips = ({ bg, color = "var(--white)", label }) => {
  return (
    <Div
      p="8px 24px"
      borderRadius="50px"
      bg={bg}
      color={color}
      lineHeight="var(--lh-link)"
      fontSize={[
        "var(--fs-text-small) !important",
        "var(--fs-text-small) !important",
        "var(--fs-text) !important",
      ]}
      fontWeight="var(--semibold-weight)"
      textAlign={["center", "left", "left", "left"]}
    >
      {label}
    </Div>
  );
};

Chips.propTypes = {
  bg: PropTypes.string,
  color: PropTypes.string,
  label: PropTypes.string,
};

const ReportResultCard = ({ report }) => {
  const {
    grade_color,
    grade_bg_color = "white",
    grade_text,
    grade_description,
    grade_recommendation,
  } = report?.grade_data || {};

  return (
    <StyledDiv
      display="flex"
      pt="24px"
      flexWrap="wrap"
      flexDirection={["column", "row", "row", "row"]}
    >
      {grade_text && (
        <Chips
          color={`var(--${grade_color})`}
          bg={`var(--${grade_bg_color})`}
          label={grade_text}
        />
      )}

      {grade_description && (
        <Chips
          color={`var(--${grade_color})`}
          bg={`var(--${grade_bg_color})`}
          label={`${grade_description}`}
        />
      )}

      {grade_recommendation && (
        <Chips
          color={`var(--${grade_color})`}
          bg={`var(--${grade_bg_color})`}
          label={`${grade_recommendation}`}
        />
      )}
    </StyledDiv>
  );
};

ReportResultCard.propTypes = {
  report: PropTypes.object,
};

export default ReportResultCard;
