import React from "react";
import Div from "@components/Div";

import PropTypes from "prop-types";
import StatusIndicator from "./StatusIndicator";
import { prefixNumberSign } from "./utils";

export const LegacyGradeComponent = ({ value, report }) => {
  const parsedConfig = report["product.report_config"]
    ? JSON.parse(report["product.report_config"])
    : null;

  const repor_array = parsedConfig ? parsedConfig?.grade : [];
  const gradeConfig = repor_array?.find((grade) => grade.grades === value);
  const { color = "white", bgColor = "grey" } = gradeConfig || {};
  const { status, analysis_type } = report;

  const prefixedSignString = prefixNumberSign(value);

  
  if (analysis_type === "manual") {
    return "-";
  }

  if (status === "completed"|| status === "expired") {
    if (prefixedSignString !== "-") {
      return (
        <StatusIndicator
          bgColor={bgColor}
          color={color}
          value={prefixedSignString}
        />
      );
    }

    if (analysis_type === "role-specific-analysis") {
      return <StatusIndicator bgColor={"grey"} color={"white"} value={value} />;
    }
    
  }

  return (
    <Div width={40} height={24} textAlign={["center"]}>
      {"-"}
    </Div>
  );
};

LegacyGradeComponent.propTypes = {
  value: PropTypes.string.isRequired,
  report: PropTypes.object.isRequired,
};

export default LegacyGradeComponent;
