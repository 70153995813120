import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { PrimaryButton, PrimaryButtonOutlined } from "@components/Button";
import Div from "@components/Div";
import { TextMediumWeight, TextLargeSemiBoldWeight } from "@components/Text";

import Config from "@src/config";

export const ErrorModal = ({
  title = "",
  handleConfirmPopup = {},
  content = "",
  hideContactUsButton = false,
}) => {
  const { messages } = useIntl();

  const redirectToContactPage = () => {
    window.open(`${Config.WP_URL}/kontakta-oss/`);
  };

  return (
    <>
      <Div mt={3} pt={1}>
        <Div display="flex" alignItems="center" flexDirection="column">
          <TextLargeSemiBoldWeight>
            {title ?? messages.error_popup_header}
          </TextLargeSemiBoldWeight>
          <TextMediumWeight textAlign="center" my="3">
            {content || messages.error_popup_paragraph}
          </TextMediumWeight>
        </Div>
        <Div display="flex" alignItems="center" justifyContent="space-around">
          <PrimaryButton
            py={2}
            px={4}
            minWidth="150px"
            minHeight="40px"
            width={[1, "auto"]}
            label={messages.label_ok}
            onClick={handleConfirmPopup}
          />
          {!hideContactUsButton && (
            <PrimaryButtonOutlined
              mx={15}
              minWidth="150px"
              minHeight="40px"
              justifyContent="center"
              onClick={redirectToContactPage}
            >
              {messages.contact_us}
            </PrimaryButtonOutlined>
          )}
        </Div>
      </Div>
    </>
  );
};
ErrorModal.propTypes = {
  title: PropTypes.string,
  handleConfirmPopup: PropTypes.func,
  content: PropTypes.string,
  hideContactUsButton: PropTypes.bool,
};

export default ErrorModal;
