import Axios from "@app/api/axios";
import config from "@src/config";

const configureAxios = () => {
  Axios.defaults.baseURL = config.BASE_URL;
  Axios.defaults.headers.common["Accept"] = "application/json";
  Axios.interceptors.request.use(function (configuration) {
    const token = localStorage.getItem("token");
    if (!configuration.headers.Authorization) {
      configuration.headers.Authorization = token ? `Bearer ${token}` : "";
    }
    return configuration;
  });
  // Optionally add a response interceptor to handle token expiration
  Axios.interceptors.response.use(
    response => response,
    error => {
      if (error.response && error.response.status === 401) {
        const currentPath = window.location.pathname;
        if (
          currentPath !== "/inlogg" &&
          currentPath !== "/admin/inlogg" &&
          currentPath !== "/avsluta-registrering" &&
          currentPath !== "/admin/avsluta-registrering"
        ) {
          localStorage.clear();
          window.location.href = "/inlogg";
        }
      }
      return Promise.reject(error);
    }
  );
};
export default configureAxios;
