import React, { useState } from "react";

import verifyRegistrationService from "@app/services/auth/verifyRegistrationService";

import { USER_REGISTRATION_COMPLETED } from "@utils/constant";
import useHistory from "@utils/useHistory";

import CompleteRegistration from "../auth/shared/CompleteRegistration";

const UserCompleteRegistration = () => {
  const history = useHistory();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const onClick = async (data, { id = "", token = "" }) => {
    localStorage.setItem("token", token);
    setLoading(true);

    try {
      await verifyRegistrationService(id, data);

      history.push(USER_REGISTRATION_COMPLETED);
    } catch (error) {
      setError(error.message);
    } finally {
      localStorage.removeItem("token", token);
      setLoading(false);
    }
  };

  return (
    <CompleteRegistration onClick={onClick} error={error} loading={loading} />
  );
};

export default UserCompleteRegistration;
