import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import customerList from "@app/services/customers/customerService";

import { PrimaryButton } from "@components/Button";
import DataTable from "@components/DataTableV2/DataTable";
import Dialog from "@components/Dialog";
import Div from "@components/Div";
import Span from "@components/Span";

import useAuthorization from "@hooks/useAuthorization";

import AdminContainer from "@layout/AdminContainer";

import { SubscriptionStatusTerms } from "@src/enum/SubscriptionStatusTerms";
import { AdminPermissions } from "@src/enum/Permissions";

import { GET_SUBSCRIPTION, SUBSCRIPTION_STATUS, ROUTES } from "@utils/constant";
import { loadServicesData } from "@utils/common";
import { convertSEK, formatCurrency, getFormattedNumber } from "@utils/utils";

import { CreateSubscriptionDialog } from "./components";

const SEARCHABLE_COLUMNS = ["id", "customer.customer_name"];

const FILTER_KEY_FROM = "filter[created_at][gte]";
const FILTER_KEY_TO = "filter[created_at][lte]";

const RENEWAL_FILTER_KEY_FROM = "filter[renewal_date][gte]";
const RENEWAL_FILTER_KEY_TO = "filter[renewal_date][lte]";

const SubscriptionList = () => {
  const { hasAllPermissions } = useAuthorization();
  const { messages } = useIntl();

  const [
    isCreateSubscriptionDialogVisible,
    setIsCreateSubscriptionDialogVisible,
  ] = useState(false);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [customersList, setCustomersList] = useState([]);
  const [reloadList, setReloadList] = useState(false);
  const [subscriptionPackages, setSubscriptionPackages] = useState([]);
  const [isFilterValuesLoaded, setIsFilterValuesLoaded] = useState(false);

  const hasWriteSubscriptionPermission = hasAllPermissions([
    AdminPermissions.AdminWriteSubscription,
  ]);

  const handleOpenCreateSubscriptionDialog = () => {
    setIsCreateSubscriptionDialogVisible(true);
  };

  const handleCloseCreateSubscriptionDialog = () => {
    setIsCreateSubscriptionDialogVisible(false);
  };

  const onCancel = () => {
    setReloadList(!reloadList);
    setShowSuccessPopup(!showSuccessPopup);
  };

  useEffect(() => {
    if (customersList.length <= 0) {
      customerList().then(result => {
        const _customers = result.data.data.map(
          ({ customer_name: name = "", id = "" }, index) => ({
            label: `${name} - #${id}`,
            value: id,
            key: index,
          })
        );
        setCustomersList(_customers);
      });
    }
  }, [customersList, subscriptionPackages]);

  //* Start Get Packages from API */
  useEffect(() => {
    const getPackages = async () => {
      const packagesData = await loadServicesData();
      const formattedPackagesData = packagesData.map(_package => ({
        code: _package.id,
        name: _package.name,
      }));

      setSubscriptionPackages(formattedPackagesData);
      setIsFilterValuesLoaded(true);
    };
    getPackages();
  }, []);
  //* End Get Packages from API */

  const dataTableConfig = {
    header: {
      title: "subscripttion_list",
      actions: [
        {
          id: "new-subscription",
          type: "button",
          label: messages.label_new_subscription,
          onClick: handleOpenCreateSubscriptionDialog,
          width: "auto",
          icon: "plus",
          variant: "header",
          isHidden: !hasWriteSubscriptionPermission,
        },
      ],
      dataTableActions: [
        {
          id: "filter",
          type: "button-tooltip",
          icon: "filter",
          variant: "header",
          borderRadius: 0,
          content: `${messages.order_filter_description}`,
        },
      ],
    },
    enable_csv_download: true,
    csv_filename: "subscriptions.csv",
    enable_filter: true,
    is_filter_options_updated: isFilterValuesLoaded,
    defaultappliedFilters: {
      "filter[status]": [SUBSCRIPTION_STATUS.ACTIVE],
    },
    filters: [
      {
        title: messages.label_date,
        id: "calendar",
        type: "calendar",
        options: [
          {
            label: messages.filter_date_from,
            value: "",
            id: [FILTER_KEY_FROM, FILTER_KEY_TO],
          },
        ],
      },
      {
        title: messages.label_status,
        id: "filter[status]",
        type: "multiselect",
        options: [
          {
            name: "filter[status]",
            value: "",
            placeholder: messages.label_status,
            id: "filter[status]",
            options: [
              {
                name: messages.title_registered,
                code: SUBSCRIPTION_STATUS.REGISTERED,
              },
              {
                name: messages.active,
                code: SUBSCRIPTION_STATUS.ACTIVE,
              },
              {
                name: messages.stopped_label,
                code: SUBSCRIPTION_STATUS.STOPPED,
              },
              {
                name: messages.terminated_label,
                code: SUBSCRIPTION_STATUS.TERMINATED,
              },
            ],
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.subscription,
        id: "filter[product_id]",
        type: "multiselect",
        options: [
          {
            name: "filter[product_id]",
            value: "",
            placeholder: messages.watchlist_label_choose,
            id: "filter[product_id]",
            options: subscriptionPackages,
            type: "multiselect",
          },
        ],
      },
      {
        title: messages.renewal_date,
        id: "calendar",
        type: "calendar",
        options: [
          {
            label: messages.filter_date_from,
            value: "",
            id: [RENEWAL_FILTER_KEY_FROM, RENEWAL_FILTER_KEY_TO],
          },
        ],
      },
    ],
    backend_querying: true,
    no_records_message: "datatable_admin_subscriptions_no_objects_found",
    enable_search: true,
    enable_query_builder: true,
    api: {
      resource: `/${GET_SUBSCRIPTION}`,
      method: "GET",
      search_fields: SEARCHABLE_COLUMNS,
      params: [
        [
          "fields",
          [
            "available_credits",
            "created_at",
            "customer_id",
            "id",
            "price",
            "product_id",
            "renewal_date",
            "status",
            "total_credits",
            "renewals",
          ],
        ],
        ["include", ["product", "customer"]],
      ],
    },
    columns: [
      {
        title: "title_id",
        db_field: "id",
        type: "id",
        sortable: true,
        width: "80px",
      },
      {
        title: "label_date",
        db_field: "created_at",
        type: "dateTime",
        sortable: true,
      },
      {
        title: "title_customer",
        db_field: "customer.customer_name",
        type: "link",
        href: value =>
          ROUTES.CUSTOMER_DETAILS.URL.replace(":id", value.customer_id),
        sortable: true,
        width: "22%",
      },
      {
        title: "subscription",
        db_field: "product.name",
        type: "text",
        sortable: true,
      },
      {
        title: "subscription_credits",
        db_field: "credits",
        type: "text",
        formatter: (_, row) =>
          `${row.available_credits} / ${row.total_credits}`,
        width: "100px",
        sortable: true,
        sortField: "available_credits",
      },
      {
        title: "watchlist_label_price",
        db_field: "price",
        type: "text",
        width: "100px",
        formatter: value => `${getFormattedNumber(convertSEK(formatCurrency(value)))} kr`,
        sortable: true,
      },
      {
        title: "renewal",
        db_field: "renewals",
        type: "text",
        formatter: value => `${value ? messages.yes_label : messages.no_label}`,
        width: "100px",
        sortable: true,
      },
      {
        title: "renewal_date",
        db_field: "renewal_date",
        type: "date",
        sortable: true,
      },

      {
        title: "label_status",
        db_field: "status",
        type: "text",
        width: "120px",
        formatter: value => `${messages[SubscriptionStatusTerms[value]]}`,
        sortable: true,
      },
      {
        type: "actions",
        width: "50px",
        actions: [
          {
            title: messages.label_details,
            type: "link",
            href: value =>
              ROUTES.SUBSCRIPTION_DETAILS.URL.replace(
                ":subscription_id",
                value.id
              ),
          },
        ],
      },
    ],
  };

  const headerConfig = [
    {
      title: messages.subscription,
    },
  ];

  return (
    <AdminContainer config={headerConfig} wide>
      <DataTable
        config={dataTableConfig}
        screenName={GET_SUBSCRIPTION}
        reloadList={reloadList}
      />

      {isCreateSubscriptionDialogVisible && (
        <CreateSubscriptionDialog
          onClose={handleCloseCreateSubscriptionDialog}
        />
      )}

      {showSuccessPopup && (
        <Dialog
          visible="displayBasic"
          draggable={false}
          width={[1, 1, 1, 500]}
          m={[3, 3, 3, "auto"]}
          onHide={onCancel}
        >
          <Div display="flex" flexDirection="column" alignItems="center">
            <Div mt={3} mb={4}>
              <Span>{messages.success_order_created}</Span>
            </Div>
            <PrimaryButton
              rounded
              semibold
              mb={[30, 0]}
              label={messages.label_done}
              onClick={onCancel}
            />
          </Div>
        </Dialog>
      )}
    </AdminContainer>
  );
};

export default SubscriptionList;
