import React, { useMemo, useRef } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import queryString from "query-string";

import Card from "@components/Card";
import Container from "@components/Container";
import Div from "@components/Div";
import { H1, H4 } from "@components/Heading";
import GDPRInformationMessage from "@components/privacyPolicies/GDPRInformationMessage";
import Section from "@components/Section";
import { SurveyForm } from "@components/Survey";
import ProgressSpinner from "@components/ProgressSpinner";
import { ColouredMediumWeight } from "../../../components/Text";

import CompleteRegistrationConfig from "./CompleteRegistrationConfig.json";

export const CompleteRegistration = ({ onClick, error, loading }) => {
  const { messages } = useIntl();
  const { search = "" } = useLocation();
  const errormsg = error;

  const surveyRef = useRef(null);

  const params = useMemo(() => queryString.parse(search), [search]);

  const surveyData = useMemo(
    () => ({
      firstname: "",
      lastname: "",
      email: params.email ?? "",
      phone: "",
      password: "",
      confirm_password: "",
    }),
    [params.email]
  );

  const handleRegistrationSubmit = data => onClick(data, params);

  const handleLoadSurveyRef = ref => {
    surveyRef.current = ref;
  };

  const handleSave = () => {
    const { data } = surveyRef?.current ?? {};

    handleRegistrationSubmit(data);
  };

  return (
    <Section
      flex={1}
      display="flex"
      justifyContent="center"
      bg="var(--site-background-color)"
      className="complete-registration"
    >
      <Container
        flex={1}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
      >
        <Div
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          mb={3}
        >
          {loading ? (
            <ProgressSpinner />
          ) : (
            <Card width={1} maxWidth="465px">
              <Div
                px={[3, 3, 4, 4]}
                py="24px"
                pb="0"
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                gridGap={3}
              >
                <H1>{messages.title_complete_registration}</H1>

                <H4 textAlign="center">
                  {messages.description_complete_registration}
                </H4>

                <Div textAlign="center">
                  <H4>{params?.email}</H4>
                </Div>

                <SurveyForm
                  showCompleteButton={true}
                  data={surveyData}
                  formConfig={CompleteRegistrationConfig}
                  onRefLoaded={handleLoadSurveyRef}
                  onComplete={handleSave}
                />
                {errormsg !== null && (
                  <Div mt={-20} mb={40} textAlign="center">
                    <ColouredMediumWeight error>
                      {messages.user_registration_error}
                    </ColouredMediumWeight>
                  </Div>
                )}
              </Div>
            </Card>
          )}
        </Div>
        <GDPRInformationMessage />
      </Container>
    </Section>
  );
};

CompleteRegistration.propTypes = {
  onClick: PropTypes.func,
  error: PropTypes.string,
  loading: PropTypes.bool,
};

export default CompleteRegistration;
