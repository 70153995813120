import React, { useState, useEffect, memo } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import hash from "object-hash";

import createShareLinkService from "@app/services/reports/createShareLinkService";

import LogoFull from "@assets/logo.png";

import Div from "@components/Div";
import { H1, H2 } from "@components/Heading";
import Icon from "@components/Icon";
import Logo from "@components/Logo";
import ProgressSpinner from "@components/ProgressSpinner";

import useAuthorization from "@hooks/useAuthorization";
import { useToast } from "@hooks/useToast";

import Config from "@src/config";
import { CustomerPermissions, AdminPermissions } from "@src/enum/Permissions";

import {
  CopyLinkDialog,
  DownloadGDPRDialog,
  GenerateLinkDialog,
} from "./components";
import { ELEMENTS_BY_TYPE } from "./utils";
import { REPORTS } from "@utils/constant";

const preprocessReportConfig = (config, personalNumber) => {
  if (!config) {
    return {};
  }

  const configString = JSON.stringify(config);

  return JSON.parse(configString.replace("[ssn]", personalNumber));
};

const CaseReport = ({ report, hideIcons: _hideIcons, isPDF = false }) => {
  const { hasAnyPermissions } = useAuthorization();
  const { messages } = useIntl();
  const { pathname } = useLocation();
  const { showErrorToast } = useToast();

  const [showGDPRForDownload, setShowGDPRForDownload] = useState(false);
  const [generatingPDF, setGeneratingPDF] = useState(false);
  const [isGenerateLinkDialogVisible, setIsGenerateLinkDialogVisible] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const hideIcons = isPDF || _hideIcons;

  const hasPDFPermission = hasAnyPermissions([
    CustomerPermissions.CustomerDownloadReport,
    AdminPermissions.AdminReadReportAnalysis,
  ]);

  const {
    report_config: config,
    report_data: data = {},
    id: report_id = "",
  } = report;
  const formattedConfig = preprocessReportConfig(config, data?.personal_number);
  const { pages = [] } = formattedConfig;
  const downloadUrl = `${Config.BASE_URL}${REPORTS}/${report_id}/report-pdf`;

  useEffect(() => {
    const handleMessage = event => {
      const allowedOrigin = window.location.origin; // Parent domain
      if (event.origin !== allowedOrigin) {
        return;
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, []);

  const generateShareLink = async () => {
    try {
      setIsLoading(true);

      const { data: { token = "" } = {} } =
        await createShareLinkService(report_id);

      if (!token) {
        throw new Error("No search link token provided");
      }

      const params = new URLSearchParams();
      params.append("id", report_id);
      params.append("token", token);

      const constructedShareLink = `${Config.APP_URL}${pathname}?${params.toString()}`;

      setShareLink(constructedShareLink);
    } catch {
      showErrorToast(messages.exception_error_message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleConfirmPopup = async () => {
    setShowGDPRForDownload(false);
    setGeneratingPDF(true);
  };

  const handleDownloadReport = () => {
    setShowGDPRForDownload(true);
  };

  const handleClose = () => {
    setShowGDPRForDownload(false);
    setTimeout(setGeneratingPDF, 2000, false);
  };

  const handleOpenGenerateLinkDialog = () => {
    setIsGenerateLinkDialogVisible(true);
  };

  const handleCloseGenerateLinkDialog = () => {
    setIsGenerateLinkDialogVisible(false);
  };

  const handleOpenCopyLinkDialog = () => {
    handleCloseGenerateLinkDialog();

    generateShareLink();
  };

  const handleCloseCopyLinkDialog = () => {
    setShareLink("");
  };

  const renderPage = (page, isPDF = false) => {
    const { elements: pageElements = [] } = page;

    return (
      <Div display="flex" flexDirection="column" gridGap={4}>
        {pageElements
          ?.filter(
            ({ elements = [] }) =>
              elements.length === 0 ||
              !elements.every(({ isHidden }) => isHidden)
          )
          .map(element => {
            const elementToRender = ELEMENTS_BY_TYPE[element?.type];
            const props = {
              ...element,
              key: `${element?.type}_${element.name}${hash(element)}`,
              isPDF,
            };

            return elementToRender?.(props, data);
          })}
      </Div>
    );
  };

  const renderHeader = (isPDF = false) => (
    <Div
      className="title-container"
      display="flex"
      flexDirection="row"
      alignItems={["flex-start", "flex-start", "center", "center"]}
      justifyContent="space-between"
      gridGap={3}
      mb={4}
      flexWrap="wrap"
    >
      <Div>
        {isPDF && (
          <H2 color="var(--blue-dark) !important" textTransform={"uppercase"}>
            {messages.title_report}
          </H2>
        )}
        <H1>{config.title}</H1>
      </Div>

      {!hideIcons && (
        <Div className="report-icons" display="flex" gridGap={3}>
          {hasPDFPermission && (
            <Icon
              p={2}
              name="download-assignment"
              color="var(--turquoise)"
              cursor="pointer"
              fontSize="var(--fs-icon-m)"
              backgroundColor="var(--site-background-color)"
              borderRadius="10px"
              onClick={handleDownloadReport}
            />
          )}

          <Icon
            p={2}
            name="share"
            color="var(--turquoise)"
            cursor="pointer"
            fontSize="var(--fs-icon-m)"
            backgroundColor="var(--site-background-color)"
            borderRadius="10px"
            onClick={handleOpenGenerateLinkDialog}
          />
        </Div>
      )}

      <Div className="report-logo">
        <Logo logo={LogoFull} width="auto" maxHeight="60px" />
      </Div>
    </Div>
  );

  return (
    <>
      <Div
        id="report"
        display="flex"
        flexDirection="column"
        gridGap={4}
        p={[3, 3, 4, 4]}
        mx={[-16, -16, 0, 0]}
      >
        {(isLoading || generatingPDF) && <ProgressSpinner />}

        {!isPDF && (
          <>
            {showGDPRForDownload && (
              <DownloadGDPRDialog
                isCase
                onClose={handleClose}
                onConfirm={handleConfirmPopup}
                downloadUrl={downloadUrl}
              />
            )}

            {isGenerateLinkDialogVisible && (
              <GenerateLinkDialog
                onCancel={handleCloseGenerateLinkDialog}
                onConfirm={handleOpenCopyLinkDialog}
              />
            )}

            {shareLink && (
              <CopyLinkDialog
                link={shareLink}
                onClose={handleCloseCopyLinkDialog}
              />
            )}

            {pages.map((page, index) => (
              <div key={`report-page-${hash(page)}`}>
                {index === 0 && renderHeader()}
                {renderPage(page)}
              </div>
            ))}
          </>
        )}

        {isPDF && (
          <Div
            className={"report-pdf"}
            display="flex"
            flexDirection="column"
            gridGap={4}
            p={[3, 3, 4, 4]}
            mx={[-16, -16, 0, 0]}
          >
            {pages.map((page, index) => (
              <div
                key={`report-pdf-page-${hash(page)}`}
                className="report-page"
              >
                {index === 0 && renderHeader(true)}
                {renderPage(page, true)}
              </div>
            ))}
          </Div>
        )}
      </Div>
    </>
  );
};

CaseReport.propTypes = {
  report: PropTypes.object.isRequired,
  hideIcons: PropTypes.bool,
  isPDF: PropTypes.bool,
};

export default memo(CaseReport);
