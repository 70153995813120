import React from "react";
import { useIntl } from "react-intl";
import PropTypes from "prop-types";

import { Model, ChoicesRestfull } from "survey-core";

import { Survey } from "survey-react-ui";
import "survey-core/i18n/swedish";
import ProgressSpinner from "@components/ProgressSpinner";

import Axios from "@app/api/axios";

import surveyTheme from "@assets/survey_theme.json";

import Config from "@src/config";

import { generateHTMLFromTemplate } from "./generateHTMLFromTemplate";

function getJwt() {
  return localStorage.getItem("token");
}

ChoicesRestfull.onBeforeSendRequest = function (sender, options) {
  const token = getJwt();
  if (token) {
    options.request.setRequestHeader("Authorization", `Bearer ${token}`);
  }
};

const cleanUp = (formConfig, messages) => {
  const jsonString = JSON.stringify(formConfig);

  const formattedJsonString = jsonString.replace(
    /{BASE_URL}/g,
    Config.BASE_URL
  );
  const result = formattedJsonString.replace(
    /\[\[(\w+?)\]\]/g,
    (match, key) => {
      return messages[key] || match;
    }
  );
  return JSON.parse(result);
};

const CUSTOMIZABLE_TAGBOX_QUESTION_NAMES = ["child", "add_on_products"];

const SurveyForm = ({
  data,
  formConfig,
  mode = "edit",
  onComplete,
  onRefLoaded,
  // onValueChanged,
  showCompleteButton,
  onClose,
}) => {
  const { messages } = useIntl();
  const cleanedFormConfig = formConfig
    ? cleanUp(formConfig, messages)
    : formConfig;

  const _survey = new Model(cleanedFormConfig);
  _survey.applyTheme(surveyTheme);
  _survey.onComplete.add(onComplete);
  _survey.locale = Config.language;
  _survey.showCompleteButton = showCompleteButton ?? false;
  _survey.completeText = messages.label_save;
  _survey.data = { ..._survey.data, ...data };
  _survey.mode = mode;
  const survey = _survey;
  onRefLoaded?.(survey);
  _survey.checkErrorsMode = "onValueChanged";

  _survey.addNavigationItem({
    title: messages.label_cancel,
    innerCss: onClose
      ? "sd-navigation__cancel-btn"
      : "sd-navigation__cancel-btn-hidden",
    action: () => {
      onClose?.();
    },
  });

  _survey.onValueChanged.add(function (sender, options) {
    const question = options.question;

    if (question && question.htmlTemplate && question.targetField) {
      question.value.forEach((panelData, index) => {
        const template = question.htmlTemplate;
        const targetField = question.targetField;

        // Generate the HTML dynamically
        const generatedHTML = generateHTMLFromTemplate(template, panelData);

        sender.data[question.name][index][targetField] = generatedHTML;
      });

      sender.data = { ...sender.data };
    }

    const dynamicFieldMappings = survey.getPropertyValue(
      "dynamicFieldMappings"
    );

    if (dynamicFieldMappings.length > 0) {
      dynamicFieldMappings.forEach(mapping => {
        const { sourceFields, targetField, htmlTemplate } = mapping;
        const sourceFieldsArray = sourceFields.split(",");
        if (sourceFieldsArray.includes(question.name)) {
          const generatedHTML = generateHTMLFromTemplate(
            htmlTemplate,
            sender.data
          );
          sender.data = { ...sender.data, [targetField]: generatedHTML };
        }
      });
    }

    // onValueChanged?.(options);
  });

  // _survey.onQuestionVisibleChanged.add((sender, options) => {
  //   // eslint-disable-next-line no-console
  //   console.log("Visible changed", sender, options);

  //   const panelIndex = options.question.parent?.parent;

  //   // eslint-disable-next-line no-console
  //   console.log("Panel Index", panelIndex);

  //   if (options.name === "apiConfiguration" && options.visible) {
  //     const apiConfigurations = sender.getValue("apiConfiguration");

  //     // eslint-disable-next-line no-console
  //     console.log("API Configurations", sender, options);

  //     if (!apiConfigurations || apiConfigurations.length === 0) {
  //       return;
  //     }

  //     apiConfigurations.map((config, index) => {
  //       const apiUrl = config.api_url;
  //       const requestBody = config.request_body;

  //       if (!apiUrl || !requestBody) {
  //         // Handle missing data
  //         const errorMessage = `Configuration ${index + 1}: Missing API URL or Request Body`;
  //         sender.setValue(`apiConfiguration[${index}].apiResult`, errorMessage);
  //         return Promise.resolve();
  //       }

  //       // Parse the request body
  //       let parsedBody;
  //       try {
  //         parsedBody = JSON.parse(requestBody);
  //       } catch (error) {
  //         const errorMessage = `Configuration ${index + 1}: Invalid JSON in request body`;
  //         sender.setValue(`apiConfiguration[${index}].apiResult`, errorMessage);
  //         return Promise.resolve();
  //       }

  //       // Make the API call
  //       return fetch(apiUrl, {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify(parsedBody),
  //       })
  //         .then(response => response.json())
  //         .then(data => {
  //           // Set API result in the corresponding `apiResult` field
  //           const resultMessage =
  //             data.message ||
  //             `Configuration ${index + 1}: API call succeeded, but no message returned.`;
  //           sender.setValue(
  //             `apiConfiguration[${index}].apiResult`,
  //             resultMessage
  //           );
  //         })
  //         .catch(error => {
  //           // Handle API errors
  //           const errorMessage = `Configuration ${index + 1}: API call failed - ${error.message}`;
  //           sender.setValue(
  //             `apiConfiguration[${index}].apiResult`,
  //             errorMessage
  //           );
  //         });
  //     });
  //   }
  // });

  // _survey.onAfterRenderQuestion.add(function (sender, options) {
  //   if (options?.question?.inputType === "password") {
  //     const inputElement = options?.htmlElement?.querySelector("input");
  //     if (inputElement) {
  //       inputElement.classList.add("p-password-input","p-inputtext","p-component");
  //       let showPassword = options?.question?.inputType === "password" ? false : true;
  //       const iconContainer = document.createElement("i");
  //       iconContainer.style.cssText = "display: inline-block; margin-left: 10px; cursor: pointer;";
  //       iconContainer.className = "icon-hideeye";
  //       iconContainer.addEventListener("click", function () {
  //         showPassword = !showPassword;
  //         inputElement.type = showPassword ? "text" : "password";
  //         iconContainer.className = showPassword
  //           ? "icon-showeye"
  //           : "icon-hideeye";
  //       });
  //       inputElement.parentNode.appendChild(iconContainer);
  //     }
  //   }
  // });

  _survey.onLoadChoicesFromServer.add((_, options) => {
    if (!CUSTOMIZABLE_TAGBOX_QUESTION_NAMES.includes(options.question.name)) {
      return;
    }

    const updatedChoices = options.serverResult.data?.map?.(item => ({
      value: item.id,
      text: `${item.name} - #${item.id}`,
    }));

    options.choices = updatedChoices;
  });

  _survey.onAfterRenderPanel.add(function (survey, options) {
    // Check if the button already exists to prevent duplicate connections
    if (
      options.panel.buttonText.length > 0 &&
      !options.htmlElement.querySelector(".btn-custom-event")
    ) {
      const button = document.createElement("button");
      button.innerText = options.panel.buttonText;
      button.className = "btn-custom-event"; // Optional: Add custom styles

      button.onclick = function () {
        const { api_url = [], ...rest } = options.panel.getDisplayValue();
        if (api_url) {
          const payload = {
            prompt: JSON.stringify(rest),
            assistant_id: "asst_iE2mBoUbDmtJ18X94bqBKq43",
          };
          Axios.post(api_url, payload).then(response => {
            const { reference_verification_summary } = response.data.response;
            const summary = options.panel.getQuestionByName("apiResult");
            summary.value = reference_verification_summary;
          });
        }
      };

      options.htmlElement.appendChild(button);
    }
  });

  if (!survey && !survey?.data) {
    return <ProgressSpinner />;
  }

  return <Survey model={survey} />;
};

SurveyForm.propTypes = {
  data: PropTypes.object,
  formConfig: PropTypes.any,
  mode: PropTypes.string,
  onComplete: PropTypes.func,
  onRefLoaded: PropTypes.func,
  onValueChanged: PropTypes.func,
  showCompleteButton: PropTypes.bool,
  onClose: PropTypes.func,
};

export default SurveyForm;
